import React from "react";
import { useState, useMemo, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./../../styles/PageTitle.module.css";
import ListTable from "../utils/Table";
import Form from "../utils/Form";
import ViewChange from "../utils/ViewChange";
import { tv_category_create, tv_category_delete, tv_category_update, all_tv_category_list } from './../../actions/LiveStreaming/tv_category';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
import Reload from "../utils/Reload";
export default function TVCategory() {
	const user = useSelector((state) => state.layout.profile)
	const dispatch = useDispatch()
	const rights = useSelector((state) => state.layout.rights)
	const location = useLocation();
	const navigate = useNavigate()
	const [flag, setFlag] = useState(false)

	const [isEdit, setIsEdit] = useState(false);
	const [form, setForm] = useState((location?.state?.form && JSON.parse(location?.state?.form)) || {});
	const [view, setView] = useState(location?.state?.view || "view_all");
	useMemo(() => {
		if (isEdit) { setView("create_new") }
		else {
			setView("view_all")
			setForm({});
		}
	}, [isEdit]);
	const path = location?.pathname.split("/")[2]
	useEffect(() => {
		setView(path != "TVCategory" ? "create_new" : "view_all");
		setForm((location?.state?.form && JSON.parse(location?.state?.form)) || {});
		setIsEdit(path == "EditTVCategory");
	}, [location]);
	const tempTableData = {
		tableTitle: "Streaming Categories", deleteRecord: tv_category_delete,
		updateRecord: tv_category_update,
		onDeleteText: "Are you sure want to delete?",
		deleteAccess: rights?.["Live Streaming"]?.["delete"] == "true",
		onUpdateText: "Are you sure want to change the status?",
		tableHead: [
			{
				id: "category_name",
				label: "Category Name",
			},
			{
				id: "image",
				label: "Image",
				isImage: true
			},
			{
				id: 'status',
				label: 'Status',
				isButtonDisplay: true

			},
			{
				id: "edit",
				label: "Update",
				access: rights?.["Live Streaming"]?.["edit"] == "true"
			},
		],
		tableBody: [
			{
				id: 0,
				tv_category_name: "Movie",
				image: "Landscape",
				edit: 0,
			},
		],
	}
	const [tableData, setTableData] = useState({ ...tempTableData });
	useMemo(() => {
		setTableData({ ...tempTableData })
	}, [rights])
	const formStructure = [
		{
			id: "1",
			type: "inputBox",
			title: "Category Name",
			name: "category_name",
			maxLength: 20,
			isCaps: true,
			regex: /^[a-zA-Z\s\&]+$/,
		}, {
			id: "20",
			type: "toggle",
			title: "Status",
			name: "status",
			default: "Active",
			display: isEdit ? "block" : "none",
			size: "6",
			options: [{ value: "Active", color: "success" }, { value: "Inactive", color: "danger" }],
		},
		{
			id: "2",
			type: "image",
			title: "Browse Image",
			subtitle: "(Resolution : 910px x 1280px) *",
			subsubsubtitle: "Support only JPG,PNG,JPEG",
			subsubtitle: "Max File Size 1MB",
			name: "image",

		},
		{
			id: "3",
			type: "button",
			title: isEdit ? "Edit" : "Create",
		},
	];
	const message = useSelector((state) => state.layout.message);

	const tv_categories = useSelector((state) => state.livestreamings.tv_categories);
	useEffect(() => {
		dispatch(all_tv_category_list())
	}, [message])
	useMemo(() => {

		if (tv_categories?.statuscode == 200) {
			const temp = tableData
			temp.tableBody = tv_categories?.data
			setTableData({ ...temp })
		}
	}, [tv_categories])
	
	const formTitle = isEdit ? "Edit Streaming Category" : "Create Streaming Category";
	const handleSubmit = (event) => {
		event.preventDefault();
		const data = new FormData();
		Object.keys(form).map((key) => data.append(key, form?.[key]));
		data.append("user", user?.id);
		formStructure.map((element) => {
			if (element.type == "image" && form?.[element.name] && typeof (form?.[element.name]) != "string") {
				const temp = form;
				temp["temp" + element.name] = form?.[element.name];
				temp[element.name] = URL.createObjectURL(form?.[element.name]);

				setForm({
					...temp,
				});
			}
		});

		const temp = tableData;
		if (isEdit) {
			dispatch(tv_category_update(data))
		}
		else {
			dispatch(tv_category_create(data))
		}
		setFlag(true)

	};
	useMemo(() => {
		if (message?.statuscode == 200 && flag) {
			const temp = tableData;
			if (isEdit) {
				temp.tableBody.map(
					(value, index) =>
						value.id == form.id && (temp.tableBody[index] = { ...form })
				);

			} else {
				temp.tableBody[temp.tableBody.length] = {
					id: temp.tableBody.length,
					...form,
					edit: temp.tableBody.length,
				};

			}
			setTableData({ ...temp });
			setIsEdit(false);
			setForm({});
			setFlag(false)
			setTimeout(() => {
				dispatch(all_tv_category_list());
				navigate("/LiveStreaming/TVCategory/", { state: { view: "view_all" } })
				setView("view_all");
			}, 1000);
		}
		else {

			setForm({ ...form, image: form?.tempimage });
		}
	}, [message])
	return (
		<>

			<ViewChange setForm={setForm} setView={setView} access={rights?.["Live Streaming"]?.["create"] == "true"} setIsEdit={setIsEdit} view={view} isEdit={isEdit} create_new={"/LiveStreaming/CreateTVCategory/"}
				view_all={"/LiveStreaming/TVCategory/"}
				reload={<Reload isClubed={true} />}
				form={form} />


			{view == "create_new" && (
				<Form
					formStructure={formStructure}
					formTitle={formTitle}
					key={"Form"}
					handleSubmit={handleSubmit}
					setForm={setForm}
					form={form}
					tableData={tableData}
					setTableData={setTableData}
					isEdit={isEdit}
					setIsEdit={setIsEdit}
				/>
			)}
			{view == "view_all" && (
				<ListTable
					tableData={tableData}
					key={"ListTable"}
					setForm={setForm}
					setTableData={setTableData}
					setIsEdit={setIsEdit}
					create_new={"/LiveStreaming/EditTVCategory"}
				/>
			)}
		</>
	);
}
