

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import SellerDetail from "../components/SellerDetail/SellerDetail"
import MoviesRented from '../components/SellerDetail/MoviesRented';
import MoviesDownload from '../components/SellerDetail/MoviesDownload';
import MoviesWatched from '../components/SellerDetail/MoviesWatched';
function SellerDetailModule() {
  return (
    <div className="App">
    
<Routes>
< Route path='/SellerDetail' element={<Layout children={SellerDetail()}/>}/>

< Route path='/MoviesDownload' element={<Layout children={MoviesDownload()}/>}/>
< Route path='/MoviesRented' element={<Layout children={MoviesRented()}/>}/>
< Route path='/MoviesWatched' element={<Layout children={MoviesWatched()}/>}/>



      </Routes>
    </div>
  );
}

export default SellerDetailModule;
