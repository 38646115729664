

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import TVCategory from '../components/LiveStreaming/TVCategory';
import Layout from '../components/Layout/Layout';
import TVChannel from '../components/LiveStreaming/TVChannel';
function LiveStreamingModule() {
  return (
    <div className="App">

      <Routes>
        < Route path='/TVCategory' element={<Layout children={TVCategory()} />} />
        < Route path='/CreateTVCategory' element={<Layout children={TVCategory()} />} />
        < Route path='/EditTVCategory' element={<Layout children={TVCategory()} />} />

        < Route path='/TVChannel' element={<Layout children={TVChannel()} />} />
        < Route path='/CreateTVChannel' element={<Layout children={TVChannel()} />} />
        < Route path='/EditTVChannel' element={<Layout children={TVChannel()} />} />



      </Routes>
    </div>
  );
}

export default LiveStreamingModule;