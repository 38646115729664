import React from "react";
import { useState, useMemo, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./../../styles/PageTitle.module.css";
import ListTable from "../utils/Table";
import Form from "../utils/Form";
import ViewChange from "../utils/ViewChange";
import { tv_channel_create, tv_channel_delete, tv_channel_update, all_tv_channel_list } from './../../actions/LiveStreaming/tv_channel';

import { all_tv_category_list } from './../../actions/LiveStreaming/tv_category';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
import Export from "../utils/Export";
import Reload from "../utils/Reload";
export default function TVChannel() {
	const dispatch = useDispatch()
	const user = useSelector((state) => state.layout.profile)
	const role = useSelector((state) => state.layout.role)
	const rights = useSelector((state) => state.layout.rights)
	const location = useLocation();
	const navigate = useNavigate()
	const [isEdit, setIsEdit] = useState(false);
	const [flag, setFlag] = useState(false)

	const [form, setForm] = useState((location?.state?.form && JSON.parse(location?.state?.form)) || {});
	const [view, setView] = useState(location?.state?.view || "view_all");
	useMemo(() => {
		if (isEdit) { setView("create_new") }
		else {
			setView("view_all")
			setForm({});
		}
	}, [isEdit]);
	const path = location?.pathname.split("/")[2]
	useEffect(() => {
		setView(path != "TVChannel" ? "create_new" : "view_all");
		setForm((location?.state?.form && JSON.parse(location?.state?.form)) || { stream_type: role == "Distributor" ? "TVOD" : "" });
		if (role == "Distributor") {
			const temp = formStructure

			temp[1]["required"] = false
			temp[1]["type"] = "inputBox"
			temp[1]["disabled"] = true
			setFormStructure([...temp])
		}
		setIsEdit(path == "EditTVChannel");
	}, [location]);
	const tempTableData = {
		tableTitle: "Channels / Events", deleteRecord: tv_channel_delete,
		updateRecord: tv_channel_update,
		onDeleteText: "Are you sure want to delete?",
		deleteAccess: rights?.["Live Streaming"]?.["delete"] == "true",
		onUpdateText: "Are you sure want to deactivate?",
		tableHead: [
			{
				id: "channel_name",
				label: "Channel / Event Name",
			},
			{
				id: "stream_category_name",
				label: "Category",
			},
			{
				id: "stream_type",
				label: "Access",
				width: "10%",
				padding: "8px 5px"
			},

			{
				id: "thumbnail",
				label: "Image",
				isImage: true,
				width: "10%",
				padding: "8px 5px"
			},

			{
				id: 'views',
				label: 'Views',
			}, {
				id: 'status',
				label: 'Status',
				isButtonDisplay: true

			},

			{
				id: "edit",
				label: "Update",
				access: rights?.["Live Streaming"]?.["edit"] == "true"
			},
		],
		tableBody: [

		], filterColumn: [
			{
				id: "1",
				title: "Select Category",
				name: "stream_category_name",
				options: ["News Channel", "Entertainment"],
			}
		]
	}
	const [tableData, setTableData] = useState({ ...tempTableData });
	useMemo(() => {
		setTableData({ ...tempTableData })
	}, [rights])
	const [formStructure, setFormStructure] = useState([
		{
			id: "1",
			type: "select",
			title: "Select Streaming Category",
			name: "stream_category",
			options: ['News Channel', 'Entertainment', 'Sports'],
			required: true

		},
		{
			id: "2",
			type: "select",
			title: "Stream Type",
			name: "stream_type",
			options: ['FREE', 'TVOD', "SVOD"],
			required: true
		},
		{
			id: "3",
			type: "inputBox",
			variant: "number",
			title: "Enter Amount",
			name: "amount",
			max: "999.00",
			min: "0.00",
			maxLength: "6",
			display: "none",
			required: false
		},
		{
			id: "4",
			type: "inputBox",
			title: "Channel / Event Name",
			name: "channel_name",
			maxLength: 50,
			regex: /^[a-zA-Z0-9\s\&]+$/,
			required: true
		},
		{
			id: "5",
			type: isEdit ? "lockedInput" : "inputBox",
			title: "Channel Live URL (.m3u8)",
			subtitle: "(.m3u8)",
			endsWith: ".m3u8",
			errorText: "File extension should be .m3u8 only",
			name: "channel_live_url",
			required: true
		},
		{
			id: "6",
			type: "select",
			title: "Publish Type",
			name: "publish_type",
			options: ['Now', 'Upcoming'],
			required: true
		},
		{
			id: "7",
			type: "inputBox",
			variant: "date",
			title: "Streaming Date",
			name: "streaming_date",
			min: new Date().toISOString().split('T')[0],
			display: "none",
			required: false
		},
		{
			id: "8",
			type: "inputBox",
			variant: "time",
			title: "Publish Time",
			name: "streaming_time",
			display: "none",
			required: false
		},
		{
			id: "20",
			display: isEdit ? "block" : "none",
			type: "toggle",
			title: "Status",
			name: "status",
			default: "Active",

			size: "6",
			options: [{ value: "Active", color: "success" }, { value: "Inactive", color: "danger" }],
		},
		{
			id: "9",
			type: "description",
			title: "Description",
			name: "description",
			required: true
		},
		{
			id: "10",
			type: "image",
			title: "Browse Thumbnail",
			subtitle: "(Resolution : 910px x 1280px)",
			subsubtitle: "Max File Size 1MB",
			subsubsubtitle: "Support only JPG,PNG,JPEG",
			name: "thumbnail",
			required: true
		},
		{
			id: "11",
			type: "image",
			subtitle: "(Resolution : 1280px x 720px)",
			subsubtitle: "Max File Size 1MB",
			subsubsubtitle: "Support only JPG,PNG,JPEG",
			title: 'Browse Poster',
			size: "4",
			name: "poster",
			required: true
		},
		{
			id: "12",
			type: "button",
			title: isEdit ? "Edit" : "Create",
		},
	]);


	const tv_channels = useSelector((state) => state.livestreamings.tv_channels);
	useEffect(() => {
		if(user?.id){const data = new FormData()
		data.append('id', user?.id)
		dispatch(all_tv_channel_list(data))}
	}, [user?.id])
	useMemo(() => {

		if (tv_channels?.statuscode == 200) {
			const temp = tableData
			temp.tableBody = tv_channels?.data.map((value) => ({ ...value, stream_category: value.stream_category_name }))
			setTableData({ ...temp })
		}
	}, [tv_channels])
	

	const formTitle = isEdit ? "Edit Channel / Event" : "Create Channel / Event";

	useMemo(() => {
		if (form?.publish_type == "Upcoming") {
			const temp = formStructure
			temp[6]['display'] = "block"
			temp[6]['required'] = true
			temp[7]['required'] = true
			temp[7]['display'] = "block"
			setFormStructure([...temp])
		}
		if (form?.publish_type == "Now") {
			const temp = formStructure
			temp[6]['display'] = "none"
			temp[6]['required'] = false
			temp[7]['required'] = false
			temp[7]['display'] = "none"
			setFormStructure([...temp])
		}

		if (form?.stream_type == "TVOD") {
			const temp = formStructure

			temp[2]['display'] = "Block"
			temp[2]['required'] = true
			setFormStructure([...temp])
		}
		else {
			const temp = formStructure

			temp[2]['display'] = "none"
			temp[2]['required'] = false
			setFormStructure([...temp])
		}
	}, [form?.publish_type, form?.stream_type])
	useMemo(()=>{
		if(isEdit){
			const temp = formStructure
				temp[8]["display"]= "block"
				setFormStructure([...temp])
		
		} else{
			const temp = formStructure
				temp[8]["display"]= "none"
				setFormStructure([...temp])
		}
	},[isEdit])

	useEffect(() => {
		dispatch(all_tv_category_list())

	}, [])
	const tv_categories = useSelector((state) => state.livestreamings.tv_categories);

	useMemo(() => {

		if (tv_categories?.statuscode == 200) {
			const tempTableData = tableData
			tempTableData.filterColumn[0]["options"] = tv_categories?.data.map((category) => (category?.category_name))
			setTableData({ ...tempTableData })
			const temp = formStructure
			temp[0]["options"] = tv_categories?.data.map((category) => (category?.category_name))

			setFormStructure([...temp])



		}
	}, [tv_categories])
	const message = useSelector((state) => state.layout.message);
	const handleSubmit = (event) => {
		event.preventDefault();
		const temp_form = form
		temp_form["tempstream_category"] = form?.["stream_category"]
		temp_form["stream_category"] = tv_categories?.data.map((option) => form?.["stream_category"] === option.category_name && option.id).filter(e => e)
		temp_form["created_by"] = user?.id
		setForm({
			...temp_form
		});

		const data = new FormData();
		Object.keys(form).map((key) => data.append(key, form?.[key]));
		data.append("user", user?.id);
		formStructure.map((element) => {
			if (element.type == "image" && form?.[element.name] && typeof (form?.[element.name]) != "string") {
				const temp = form;
				temp["temp" + element.name] = form?.[element.name];
				temp[element.name] = URL.createObjectURL(form?.[element.name]);

				setForm({
					...temp,
				});
			}
		});

		const temp = tableData;
		if (isEdit) {
			dispatch(tv_channel_update(data))
		}
		else {
			dispatch(tv_channel_create(data))
		}
		setFlag(true)
	};
	useMemo(() => {
		if (message?.statuscode == 200 && flag) {
			const temp = tableData;
			if (isEdit) {
				temp.tableBody.map(
					(value, index) =>
						value.id == form.id && (temp.tableBody[index] = { ...form })
				);

			} else {
				temp.tableBody[temp.tableBody.length] = {
					id: temp.tableBody.length,
					...form,
					edit: temp.tableBody.length,
				};

			}
			setTableData({ ...temp });
			setIsEdit(false);
			setForm({ stream_type: role == "Distributor" ? "TVOD" : "" });
			setFlag(false)
			setTimeout(() => {
				const data = new FormData()
				data.append('id', user?.id)
				dispatch(all_tv_channel_list(data));
				navigate("/LiveStreaming/TVChannel/", { state: { view: "view_all" } })
				setView("view_all");
			}, 1000);

		}
		else {
			const temp = form
			temp["thumbnail"] = form?.tempthumbnail
			temp["poster"] = form?.tempposter
			temp["stream_category"] = form?.tempstream_category
			setForm({ ...temp });
		}
	}, [message])
	return (
		<>

			<ViewChange
				setForm={setForm}
				setView={setView}
				access={rights?.["Live Streaming"]?.["create"] == "true"}
				create_new={"/LiveStreaming/CreateTVChannel"}
				view_all={"/LiveStreaming/TVChannel/"}
				reload={<Reload isClubed={true} />}
				form={form} export_excel={
					<Export
						fileName={"Live Streaming"}
						access={rights?.["Live Streaming"]?.["export_excel"] == "true"}
						isClubed={true}
						exportData={tableData?.exportData || tableData?.tableBody}
						headings={tableData?.tableHead.map((value) => value.label)}
					/>
				} setIsEdit={setIsEdit} view={view} isEdit={isEdit} />


			{view == "create_new" && (
				<Form
					formStructure={formStructure}
					formTitle={formTitle}
					key={"Form"}
					handleSubmit={handleSubmit}
					setForm={setForm}
					form={form}
					tableData={tableData}
					setTableData={setTableData}
					isEdit={isEdit}
					setIsEdit={setIsEdit}
				/>
			)}
			{view == "view_all" && (
				<ListTable
					tableData={tableData}
					key={"ListTable"}
					setForm={setForm}
					setTableData={setTableData}
					setIsEdit={setIsEdit}
					create_new={"/LiveStreaming/EditTVChannel"}
				/>
			)}
		</>
	);
}
