import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import SellerDetail from "./SellerDetailModule";
import Seller from "../components/Seller/Seller";

function CustomerModule() {
  return (
    <div className="App">
      <Routes>
        <Route path="/Seller" element={<Layout children={Seller()} />} />
        <Route
          path="/SellerDetail/*"
          element={<SellerDetail key="SellerDetail" />}
        />
      </Routes>
    </div>
  );
}

export default CustomerModule;
