
import { MESSAGE,TV_CATEGORIES } from '../../constants/actionTypes';
import * as api from '../../api/index.js';


export const tv_category_create = (formData) => async (dispatch) => {
    try {
      const { data } = await api.tv_category_create(formData);
      dispatch({ type: MESSAGE, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };

  export const tv_category_update = (formData) => async (dispatch) => {
    try {
      const { data } = await api.tv_category_update(formData);
      dispatch({ type: MESSAGE, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  export const tv_category_delete = (formData) => async (dispatch) => {
    try {
      const { data } = await api.tv_category_delete(formData);
      dispatch({ type: MESSAGE, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  export const all_tv_category_list = (formData) => async (dispatch) => {
    try {
      const { data } = await api.all_tv_category_list(formData);
      
      dispatch({ type: TV_CATEGORIES, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  