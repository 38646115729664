
export const MESSAGE = 'MESSAGE';
export const MESSAGE1 = 'MESSAGE1';
export const MESSAGE2 = 'MESSAGE2';
export const MESSAGE3= "MESSAGE3"
export const MESSAGE5 = "MESSAGE5"
export const LOGGEDIN = 'LOGGEDIN';
export const PROFILE = 'PROFILE';



// Masters
export const CATEGORIES = 'CATEGORIES'
export const SUBCATEGORIES= 'SUBCATEGORIES'
export const CASTS= 'CASTS'
export const GENRE= 'GENRE'
export const LANGUAGES= 'LANGUAGES'
export const COUNTRY = 'COUNTRY'
export const STATELIST = 'STATELIST'
export const CITYLIST = "CITYLIST"
export const AREALIST = "AREALIST"
export const LOCALITY = "LOCALITY"
// Subscription
export const SUBSCRIPTIONS = 'SUBSCRIPTIONS'

// producer

export const PRODUCER = 'PRODUCER'
export const PRODUCERMOVIE = 'PRODUCERMOVIE'
export const PRODUCERFORMDATA = 'PRODUCERFORMDATA'
export const PRODUCERFORMDETAILS = 'PRODUCERFORMDETAILS'


//  subTitle delete
export const SUBTITLE = "SUBTITLE"
export const AUDIOFILE = "AUDIOFILE"
export const COUNTRYFILE = "COUNTRYFILE"

// Customer
export const CUSTOMERS = "CUSTOMERS"
export const CUSTOMER = "CUSTOMER"
export const ACTCUSTOMERS = "ACTCUSTOMERS"

//HIGHLIGHT
export const HIGHLIGHT = "HIGHLIGHT"

//PROMOTION
export const PROMOTION = "PROMOTION"

// Live Streaming
export const TV_CATEGORIES = "TV_CATEGORIES"
export const TV_CHANNELS = "TV_CHANNELS"

//Seller
export const SELLERS = 'SELLERS'
export const SELLER = 'SELLER'


// Merchandise
export const NOTIFICATIONS = "NOTIFICATIONS"
export const NOTIFICATIONS_COUNT = "NOTIFICATIONS_COUNT"

export const SLIDER_BANNERS = "SLIDER_BANNERS"

// Movie
export const MOVIES = "MOVIES"
export const MOVIE = "MOVIE"
export const UPCOMING_MOVIES = "UPCOMING_MOVIES"
export const MOVIE_WATCH_USER = "MOVIE_WATCH_USER"

// Video Submission
export const VIDEOSUBMISSION = 'VIDEOSUBMISSION'
export const VIDEOSUBMISSIONDETAILS = 'VIDEOSUBMISSIONDETAILS'

// Transaction
export const TRANSACTIONS = "TRANSACTIONS"
export const TRANSACTIONDATELIS = "TRANSACTIONDATELIS"
export const ALLTRANSACTIONS = 'ALLTRANSACTIONS'

// Watchhours
export const WATCHHOURS = "WATCHHOURS"

// Web Series
export const EPISODES = "EPISODES"
export const EPISODE = "EPISODE"
export const SERIES = "SERIES"
export const SERIESDETAILS = "SERIESDETAILS"

export const EPISODE_WATCH_USER = "EPISODE_WATCH_USER"
export const SEASON = "SEASON"


// Setting
export const ABOUTUS = "ABOUTUS"
export const PRIVACYPOLICY = "PRIVACYPOLICY"
export const TERMSCONDITIONS = "TERMSCONDITIONS"
export const REFUNDPOLICY = "REFUNDPOLICY"


// Dashboard
export const TOTALUSERS = "TOTALUSERS"
export const DASHBOARDCOUNT = "DASHBOARDCOUNT"
export const DASHBOARDMOVIES = "DASHBOARDMOVIES"
export const TOTALSUBSCRIBEUSER = "TOTALSUBSCRIBEUSER"
export const MONTHLYREVENUE = "MONTHLYREVENUE"
export const TOTALREVENUE = "TOTALREVENUE"
export const TV_SHOW_DASHBOARD = "TV_SHOW_DASHBOARD"
export const RECENT_SUBSCRIBER_DASHBOARD = "RECENT_SUBSCRIBER_DASHBOARD"
export const DAILY_REVENUE = "DAILY_REVENUE"
export const RENEWAL = "RENEWAL"



// Complaint
export const COMPLAINTS = "COMPLAINTS"
