import React from "react";
import { useState, useMemo, useEffect } from "react";
import ListTable from "../utils/Table";
import Form from "../utils/Form";
import ViewChange from "../utils/ViewChange";
import {
  sliderbanner_create,
  sliderbanner_delete,
  sliderbanner_update,
  all_sliderbanner_list,
} from "../../actions/sliderbanner";
import { all_movie_list } from "../../actions/Movie/video";
import { all_series_list } from "../../actions/WebSeries/series";
import { all_category_list } from "../../actions/Masters/category";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { all_subcategory_list } from "../../actions/Masters/subcategory";
import { Movie } from "@mui/icons-material";
export default function SliderBanner() {
  const user = useSelector((state) => state.layout.profile);
  // console.log(user,"newUaser")
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [form, setForm] = useState(
    (location?.state?.form && JSON.parse(location?.state?.form)) || {}
  );
  const [view, setView] = useState(location?.state?.view || "view_all");
  // console.log(location, view, "5555");
  const [isEdit, setIsEdit] = useState(false);
  const [date, setDate] = useState("");


  const path = location?.pathname.split("/")[2];

  const [tableData, setTableData] = useState({
    tableTitle: "Slider Banner",
    deleteRecord: sliderbanner_delete,
    updateRecord: sliderbanner_update,
    customisedStatusUpdateMessage: true,
    onDeleteText: "Are you sure want to delete Slider Banner?",
    onActiveText: "Are you Sure want to Activate Slider Banner?",
    onInactiveText: "Are you Sure want to Inactivate Slider Banner?",
    tableHead: [
      // {
      //   id: "videoType",
      //   label: "Category Type",
      // },
      {
        id: "slider_name",
        label: "Slider name",
      },

      {
        id: "web_banner",
        label: "Image",
        isImage: true,
      },

      {
        id: "set_sequence",
        label: "Sequence",
        align: "center",
      },
      {
        id: "expiry_date1",
        label: "Expired on",
        isSpecial: true,
        // color:"red",
        align: "left",
      },
      {
        id: "status",
        label: "Status",
        isButtonDisplay: true,

      },
      {
        id: "edit",
        label: "Update",
      },
    ],
    tableBody: [],
    filterColumn: [
      // {
      //   id: "3",
      //   title: "Category",
      //   name: "videoType",
      //   options: ["Movie", "Series"],
      // },
    ],
  });
  useEffect(() => {
    setView(path != "SliderBanner" ? "create_new" : "view_all");
    setForm(
      (location?.state?.form && JSON.parse(location?.state?.form)) || {
        ...form,
        set_sequence: tableData?.tableBody?.length + 1,
      }
    );
    setIsEdit(path == "EditSliderBanner");
  }, [location]);

  const [formStructure, setFormStructure] = useState([
    {
      id: "1",
      type: "inputBox",
      title: "Slider Name",
      // options: ["Movie1", "Movie2", "Movie3"],
      name: "slider_name",
      // display: "none",
    
      required: true,
    },
   
    {
      id: "2",
      type: "lockedInput",
      variant: "number",
      title: "Set Sequence",
      name: "set_sequence",
      min: "1",
      max: "99",
      required: true,
    },
    
    {
      id: "12",
      type: isEdit ? "lockedInput" : "inputBox",
      title: "Upload Mp4 URL",
      endsWith: ".mp4",
      errorText: "File extension should be .mp4 only",
      name: "slider_video_link",
      required: true,
    },
    {
      id: "4",
      type: "inputBox",
      variant: "date",
      title: "Expired on",
      min: new Date().toISOString().split("T")[0],
      name: "expiry_date",
      required: true,
    },
    {
      id: "3",
      type: "toggle",
      title: "Status",
      name: "status",
      default: "Active",
      display: "none",
      size: "6",
      options: [
        { value: "Active", color: "success" },
        { value: "Inactive", color: "danger" },
      ],
    },
    {
      id: "5",
      type: "image",
      title: "Poster Banner",
      subtitle: "(Resolution : 1920 px * 1080px) *",
      subsubtitle: "Max File Size 1MB",
      subsubsubtitle: "Support only JPG,PNG,JPEG",
      errorText: "File extension should be .mp4 only",
      name: "web_banner",
      required: true,
      size:"6"
    },

    {
      id: "6",
      type: "button",
      title: isEdit ? "Edit" : "Create",
    },
  ]);
  useMemo(() => {
    if (isEdit) {
      setView("create_new");
      const temp = formStructure
      temp[4]["display"] = "block"
      setFormStructure([...temp])
    } else {
      setView("view_all");
      setForm({});
    }
  }, [isEdit]);
  const sliderbanners = useSelector(
    (state) => state.merchandise.slider_banners
  );
  // console.log(sliderbanners, "111111");
  useEffect(() => {
    dispatch(all_sliderbanner_list());
  }, []);
  // const movies = useSelector((state) => state.movies.movies);

  // useEffect(() => {
  //   const data = new FormData();
  //   data.append("id", user?.id);
  //   data.append("movie_subcategory", "");
  //   if(movies?.statuscode!=200)
  //   {

  //     dispatch(all_movie_list(data));
  //   }
  // }, [user?.id]);

  // const parth = new Date()

  // setDate(
  // 	parth.getFullYear() + '-' + (parth.getMonth() + 1) + '-' + parth.getDate())
  useMemo(() => {
    if (sliderbanners?.statuscode == 200) {
      const temp = tableData;
      const today = new Date();
      today.setDate(today.getDate() - 1);
      temp.tableBody = sliderbanners?.data?.map((ele) => ({
        ...ele,
        expiry_date1:
          new Date(ele?.expiry_date) > today ? (
            <p>{ele?.expiry_date}</p>
          ) : (
            <p style={{ color: "red" }}>Expired</p>
          ),
        edit1: !(today <= new Date(ele?.expiry_date)),
  
      }));

      setTableData({ ...temp });
      setForm({ ...form, set_sequence: tableData?.tableBody?.length + 1 });
    }
  }, [sliderbanners]);
  // console.log(sliderbanners, "4545445");

  const formTitle = isEdit ? "Edit Slider" : "Add Slider";

  // useMemo(()=>{
  //   if(isEdit){

  //   }
  // },[isEdit])
  // useMemo(() => {

  //     if (movies?.statuscode == 200) {
  //       const temp = formStructure;
  //       const tempForm = form;
  //       const tempFilter = tableData;
  //       temp[0]["options"] = movies?.data
  //         .map(
  //           (movie) => movie?.video_name
  //         )
  //         .filter((e) => e);
  //       //   tempFilter.filterColumn[1]["options"] =
  //       setFormStructure([...temp]);
  //       setForm({ ...tempForm });
  //       setTableData({ ...tempFilter });
  //     }
  
    

  // }, [movies, isEdit]);

  const message = useSelector((state) => state.layout.message);
  // console.log(movies, "8456");

  const handleSubmit = (event) => {
    event.preventDefault();
    // console.log(form, "4444");
    const data = new FormData();
    // const temp_movie_category = form;
    // temp_movie_category["tempvideo"] = form?.["video"];
    // temp_movie_category["video"] = movies?.data
    //   .map(
    //     (movie) =>
    //       form?.video == movie?.video_name &&
    //       movie.id
    //   )
    //   .filter((e) => e);
    // setForm({
    //   ...temp_movie_category,
    // });

    Object.keys(form).map(
      (key) => key != "expired_on" && data.append(key, form?.[key])
    );
    data.append("user" , user?.id)
    // const temp_form1 = form;
    // temp_form1["banner_name"] = form?.["tempbanner_name"];
    // temp_form1["video"] = form?.["tempvideo"];
    // setForm({
    //   ...temp_form1,
    // });

    if (isEdit) {
      dispatch(sliderbanner_update(data));
      // console.log("parth gohel123");
    } else {
      dispatch(sliderbanner_create(data));
    }
  };
  useMemo(() => {
    if (message?.statuscode == 200) {
      setIsEdit(false);
      setForm({});
      setTimeout(() => {
        dispatch(all_sliderbanner_list());
      }, 1000);
      setView("view_all");
      navigate("/SliderBanner/SliderBanner/", { state: { view: "view_all" } });
    } else {
      setForm({ ...form });
    }
  }, [message]);
  return (
    <>
      <ViewChange
        setForm={setForm}
        setView={setView}
        setIsEdit={setIsEdit}
        view={view}
        isEdit={isEdit}
        create_new={"/SliderBanner/CreateSliderBanner/"}
        view_all={"/SliderBanner/SliderBanner/"}
        form={form}
      />

      {view == "create_new" && (
        <Form
          formStructure={formStructure}
          handleSubmit={handleSubmit}
          formTitle={formTitle}
          key={"Form"}
          setForm={setForm}
          form={form}
          tableData={tableData}
          setTableData={setTableData}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
        />
      )}
      {view == "view_all" && (
        <ListTable
          tableData={tableData}
          key={"ListTable"}
          setForm={setForm}
          setTableData={setTableData}
          setIsEdit={setIsEdit}
          create_new={"/SliderBanner/EditSliderBanner"}
        />
      )}
    </>
  );
}
