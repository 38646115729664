export const FormStructure = (isEdit) => {
  return [
    
      {
        id: "1",
        type: "select",
        title: "Video Category",
        name: "video_category",
        options: ["Portrait", "Landscape"],
        required: true,
      },
      {
        id: "2",
        type: "select_multiple",
        title: "Sub Category",
        maxSelections: "5",
        name: "video_subcategory",
        options: ["Portrait", "Landscape"],
        errorText: "You can select max 5 Sub Category at a time",
        required: true,
      },
      
      {
        id: "3",
        type: "inputBox",
        title: "Product Name",
        name: "video_name",
        maxLength: "100",
        // regex: /^[a-zA-Z0-9\s\&]+$/,
        isCapitalise: true,
        required: true,
      },
      {
        id: "4",
        type: "select",
        title: "Language",
        name: "video_language",
        options: ["English", "Hindi", "Gujarati"],
        required: true,
      },
      {
        id: "5",
        type: "select_multiple",
        title: "Tags",
        name: "video_tag",
        maxSelections: "5",
        options: ["English", "Hindi", "Gujarati"],
        errorText: "You can select max 5 Tags at a time",
        required: true,
        isPlusBtnOpen :true,
      },  
      {
        id: "6",
        type: "select",
        title: "Publish Type",
        name: "publish_type",
        options: ["Now", "Later"],
        required: true,
      },
      {
        id: "7",
        type: "inputBox",
        variant: "date",
        title: "Publish Date",
        min: new Date().toISOString().split("T")[0],
        name: "release_date",
        display: "none",
        required: true,
      },{
        id: "8",
        type: "inputBox",
        variant: "time",
        title: "Publish Time",
        name: "publish_time",
        display: "none",
        required: true,
      },

      {
        id: "9",
        type: "inputBox",
        title: "Price",
        name: "price",
        regex: /^[0-9\.]+$/,
        // disabled:isEdit ? true :false,
        required: true,
      },
      {
        id: "10",
        type: isEdit ? "lockedInput" : "inputBox",
        title: "Upload Mp4 URL",
        endsWith: ".mp4",
        errorText: "File extension should be .mp4 only",
        name: "video_link_mp4",
        required: true,
      },

      {
        id: "11",
        type: "inputBox",
        variant: "date",
        title: "Video End Date",
        min: (() => {
          const tomorrow = new Date();
          tomorrow.setDate(tomorrow.getDate() + 1); // Get tomorrow's date
          return tomorrow.toISOString().split("T")[0];
        })(),
        name: "video_end_date",
        required: true,
      },
      {
        id: "12",
        type: "inputBox",
        title: "Upload Orders URL",
        name: "orders_url",
        // required: true,
      },
      {
        id: "13",
        type: "inputBox",
        title: "Upload Location URL",
        name: "location_url",
      },
      {
        id: "14",
        type: "phone",
        title: "Mobile Number",
        maxLength: 12,
        name: "mobile_number",
        required: true,
      },
      {
        id: "15",
        type: "inputBox",
        title: "Description",
        name: "video_description",
        maxLength: "150",
        size: "12",
        isCapitalise: true,
        required: true,
      },
     
      {
        id: "16",
        type: "toggle",
        title: "Status",
        name: "status",
        default: "Active",
        display: "none",
        size: "12",
        options: [
          { value: "Active", color: "success" },
          { value: "Inactive", color: "danger" },
        ],
      },
      {
        id: "17",
        type: "image",
        subtitle: "(Resolution : 1920px x 1080px) *",
        subsubtitle: "Max File Size 1MB",
        subsubsubtitle: "Support only JPG,PNG,JPEG",
        title: "Browse Poster",
        name: "video_poster",
        size: 4,
        required: true,
      },
      {
        id: "18",
        type: "button",
        title: isEdit ? "Edit" : "Create",
      },
     
    ]
}
