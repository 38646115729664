
import { MESSAGE,TV_CHANNELS } from '../../constants/actionTypes';
import * as api from '../../api/index.js';


export const tv_channel_create = (formData) => async (dispatch) => {
    try {
      const { data } = await api.tv_channel_create(formData);
      dispatch({ type: MESSAGE, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };

  export const tv_channel_update = (formData) => async (dispatch) => {
    try {
      const { data } = await api.tv_channel_update(formData);
      dispatch({ type: MESSAGE, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  export const tv_channel_delete = (formData) => async (dispatch) => {
    try {
      const { data } = await api.tv_channel_delete(formData);
      dispatch({ type: MESSAGE, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  export const all_tv_channel_list = (formData) => async (dispatch) => {
    try {
      const { data } = await api.all_tv_channel_list(formData);
      
      dispatch({ type: TV_CHANNELS, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  