
import { MESSAGE, CUSTOMERS, CUSTOMER,ACTCUSTOMERS, SELLER, SELLERS } from './../constants/actionTypes';
import * as api from './../api/index.js';




export const customer_update = (formData) => async (dispatch) => {
  try {
    const { data } = await api.customer_update(formData);
    dispatch({ type: MESSAGE, payload: data });


  } catch (error) {
    console.log(error);
  }
};

export const all_seller_list = (formData) => async (dispatch) => {
  try {
    const { data } = await api.all_seller_list(formData);

    dispatch({ type: SELLERS, payload: data });


  } catch (error) {
    console.log(error);
  }
};

export const seller_detail_admin = (formData) => async (dispatch) => {
  try {
    const { data } = await api.seller_detail_admin(formData);

    dispatch({ type: SELLER, payload: data });


  } catch (error) {
    console.log(error);
  }
};
