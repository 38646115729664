import * as actionType from "../constants/actionTypes";

const livestreamingsReducer = (state = { livestreamingsData: null }, action) => {
	switch (action.type) {
		
		case actionType.TV_CATEGORIES:
			return {...state,tv_categories: action.payload};
			case actionType.TV_CHANNELS:
			return {...state,tv_channels: action.payload};
	
			
		default:
			return state;
	}
};

export default livestreamingsReducer;
