import React from 'react';
import { useState, useMemo } from 'react';
import { Link } from "react-router-dom";
import styles from './../../styles/PageTitle.module.css'
import ListTable from '../utils/Table'
import { useLocation } from "react-router-dom";
export default function MoviesWatched(data) {
  // console.log(data)
  const [tableData, setTableData] = useState({
    tableTitle: "Video Watched",
    disableDelete: true,
    tableHead: [

      {
        id: 'name1',
        label: "Store Name",
        isSpecial:true,
        align:"left"
        // link:"/Customer/CustomerDetail/MoviesWatched",
      },
      {
        id: 'category_name',
        label: 'Category Name',
      },
     
      {
        id: 'video_language',
        label: 'language',
      },
      {
        id: 'video_watch_count',
        label: 'Watch Count',
      },
    ],
    tableBody: [

    ]
  })
  // const [form, setForm] = useState({})
  const [isEdit, setIsEdit] = useState(false)
  // useMemo(() => {
  //   const temp = tableData
  //   temp.tableBody= data != [] ? data?.map((state) => ({
  //     ...state,
  //     name1: state?.video?.video_name?.length <= 40 ? state?.video?.video_name : state?.video?.video_name?.substring(0, 40) + "...",
  //     category_name : state?.video?.video_category,
  //     video_language : state?.video?.video_language,
  //     video_watch_count : state?.count
  //   })) : [] 
  //   setTableData({ ...temp })
  // }, [data])
  useMemo(() => {
    if (data && Array.isArray(data.data)) { // Check if data.data is an array
      const newData = data.data.map((state) => ({
        ...state,
        name1: state?.video?.video_name?.length <= 40 ? state?.video?.video_name : state?.video?.video_name?.substring(0, 40) + "...",
        category_name: state?.video?.video_category,
        video_language: state?.video?.video_language,
        video_watch_count: state?.count
      }));
      setTableData({ ...tableData, tableBody: newData });
    } else {
      setTableData({ ...tableData, tableBody: [] });
    }
  }, [data]);
  
  
  return (
    <>



      <ListTable tableData={tableData} key={"ListTable"} setTableData={setTableData} setIsEdit={setIsEdit} />


    </>
  );
}
