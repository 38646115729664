import * as actionType from "../constants/actionTypes";

const sellersReducer = (state = { sellersData: null }, action) => {
	switch (action.type) {

		case actionType.SELLERS:
			return { ...state, sellers: action.payload };
		case actionType.SELLER:
			return { ...state, seller: action.payload };
	
		default:
			return state;
	}
};

export default sellersReducer;
