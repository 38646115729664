import React, { useEffect } from "react";

import RegisteredUsers from "./elements/RegisteredUsers";

import RecentlyAddedMovies from "./elements/RecentlyAddedMovies";

import {
  movies_data_dashboard,
  count_on_dashboard,
  total_subscribe_user,
  most_watch_tv_show_dashboard,
  recent_subscriber_dashboard,
} from "../../actions/dashboard";
import { useDispatch, useSelector } from "react-redux";

import HighestRedirectVideoGraph from "./elements/HighestRedirectVideoGraph";
import HighestWatchedVideoGraph from "./elements/HighestWatchedVideoGraph";
import RegisteredSeller from "./elements/RegisteredSeller";

export default function Dashboard() {
  const user = useSelector((state) => state.layout.profile);
  const dispatch = useDispatch();
  const data = new FormData();
  data.append("id", user?.id);
  useEffect(() => {
    dispatch(movies_data_dashboard(data));
    dispatch(count_on_dashboard(data));
    // dispatch(total_subscribe_user(data));
    // dispatch(most_watch_tv_show_dashboard(data))
    dispatch(recent_subscriber_dashboard(data));
  }, [user]);
  const registeredUsers = useSelector((state) => state.dashboard.totalusers);

  const recently_added_data = useSelector(
    (state) => state.dashboard.dashboardmovies?.recently_added
  );
  const most_watch_video = useSelector(
    (state) => state.dashboard.dashboardmovies?.most_view
  );

  const most_redirect_video = useSelector(
    (state) => state.dashboard.dashboardmovies?.most_redirect
  );
  // console.log(most_watch_movies , "ddfsfdfsfafesdfs")

  return (
  
    <>
    <RegisteredUsers registeredUsers={registeredUsers} />
    <RegisteredSeller registeredUsers={registeredUsers} />

    {/* <HighestRedirectVideoGraph most_redirect_video ={most_redirect_video} /> */}
    <HighestWatchedVideoGraph most_view_video = {most_watch_video} />
    <RecentlyAddedMovies recently_added_data={recently_added_data} />
  </>
  );
}
